import '../../css/share/common.scss';
import './layout/_header';
import { lazyload } from "./components/_lazyload.js";

$(function() {


    $('a[href$="#"]').click(function(e) {
        e.preventDefault();
    });

    let _hash, _scrollTop;
    $('a[href^="#anchor_"]').click(function(e) {
        e.preventDefault();
        _hash = $(this).attr('href');
        _hash = _hash.substr(_hash.indexOf('#'));
        _scrollTop = $(_hash).offset().top - $('.header').outerHeight(true) - $('.folder_label').outerHeight(true);
        console.log($('.header').outerHeight(true));
        TweenMax.to('html, body', 1, { scrollTop: _scrollTop, ease: Quart.easeOut });
    });

    $('.backtotop').click(function() {
        gsap.to('html, body', { duration: .7, scrollTop: 0, ease: 'power4' })
    });

    //--------------------- imagesLoaded ---------------------//
    function removeLoading() {
        $(".loading").fadeOut(function() {
            $(this).remove();
        });
    }

    imagesLoaded("html, body", function() {
        removeLoading();
        lazyload();
    });
});