$(function() {

    const $window = $(window);
    const winW = $window.width();
    // gsap.set($('.header_cate_li').find('.nav_link'), { y: 40, opacity: 0 })

    // $('.header_cate_li').hover(function() {
    //     $(this).addClass('show');
    //     gsap.to($(this).find('.nav_link'), { duration: .15, y: 0, opacity: 1, stagger: { each: 0.08, from: 'center' } })
    // }, function() {
    //     gsap.set($(this).find('.nav_link'), { y: 40, opacity: 0 })
    //     $(this).removeClass('show');
    // });

    //nav ------------------------------------------
    $('.nav_li').hover(function() {
        $(this).addClass('active');
    }, function() {
        $(this).removeClass('active');
    });
    $('.nav_li').focusin(function() {
        $(this).addClass('active');
    });
    $('.nav_li').focusout(function() {
        $(this).removeClass('active');
    });

    if (winW > 1024) {
        ScrollTrigger.create({
            start: 'top -80',
            end: 99999,
            toggleClass: { className: 'active', targets: '.header, .backtotop' }
        });
    }

    //menu選單 手機板-------------------------------------------
    if (winW <= 1024) {

        // $('.nav_hide').children('.subnav_row').remove();

        const header = $('.header');
        const hamburger = $('.hamburger'),
            nav = $('.nav'),
            nav_link = $('.nav_link'),
            subnav_row = nav.find('.subnav_row'),
            navReset = function() {
                nav_link.attr('o', 1);
                nav.clearQueue();
                subnav_row.slideUp(0);
            };
        let o = true;

        TweenMax.set(nav, { y: '100vh' });
        hamburger.click(function() {
            if (o) {
                if (winW <= 950) navReset();
                TweenMax.to(nav, .3, { y: '0vh', ease: Power3.easeOut });
                header.addClass('show');
                o = false;
            } else {
                TweenMax.to(nav, .3, { y: '100vh', ease: Power3.easeOut });
                header.removeClass('show');
                o = true;
            }
        });

        navReset();
        nav_link.click(function(e) {
            var _this = $(this),
                _thisSubnav = _this.siblings('.subnav_row'),
                _o = _this.attr('o');
            if (_thisSubnav.length <= 0) return;
            e.preventDefault();
            if (_o == 1) {
                _thisSubnav.clearQueue();
                _thisSubnav.slideDown();
                _this.attr('o', 0);
            } else {
                _thisSubnav.slideUp();
                _this.attr('o', 1);
            }
        });

    }
});